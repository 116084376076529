.about-me-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0 0 0;
    position: relative;
    z-index: 1;
}

.about-me-parent {
    max-width: 1000px;
    width: 70%;
}

.about-me-card {
    display: flex;
    width: 100%;
    box-shadow: 0 0px 20px -2px #1f2235;
    position: relative;
    z-index: 2;
}

.about-me-profile {
    /* width: 50%; */
    /* background-image: url("../../assets/AboutMe/img1.jpg"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.about-me-details {
    width: 100%;
    text-align: justify;
    padding: 30px;
}

.about-me-description {
    font-size: 14px;
}

.about-me-highlights {
    margin: 80px 0;
}

.highlght-heading {
    font-family: "Poppins Bold";
    font-size: 16px;
    margin: 0 0 5px 0;
}

.highlight {
    display: flex;
    align-items: center;
    margin: 5px 0;
    font-size: 14px;
}

.highlight-blob {
    height: 12px;
    width: 12px;
    margin: 0 10px 0 0;
    background-color: #ff5823;
    border-radius: 50%;
}

.about-me-options .highlighted-btn {
    margin: 0 0 0 30px;
}

/*IPAD PRO RESPONSIVENESS*/
@media only screen and (max-width: 1110px) {
    .about-me-parent {
        width: 88%;
    }
}
@media only screen and (max-width: 898px) {
    .about-me-profile {
        display: none;
    }
    .about-me-details {
        width: 100%;
    }
}
@media only screen and (max-width: 466px) {
    .about-me-options {
        display: flex;
        flex-direction: column;
    }
    .about-me-options .highlighted-btn {
        margin: 24px 0 0 0 !important;
    }
    .about-me-options button {
        width: 100%;
    }
}
